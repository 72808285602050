@media (min-width: 320px) {
  .modal-create-recipe {
    position: absolute;
    top: 40px;
    bottom: 40px;
    left: 20px;
    right: 20px;
    overflow: scroll;
    background: white;
    height:max-content;
  }

  .form-create-recipe input {
    display: block;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
    font-size: 16px;
    color: #6a7684;
    background: #eef2f7;
    width: 70%;
  }
}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #edf2f7d7;
}

.form-create-recipe input {
  display: block;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  font-size: 16px;
  color: #6a7684;
  background: #eef2f7;
}

@media (min-width: 1024px) {
  .modal-create-recipe {
    position: absolute;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    width: 750px;
    border: none;
    box-shadow: rgba(0, 0, 0, 0.25) 1px 3px 5px 0px;
    padding: 0;
    outline: none;
    overflow: auto;
  }
}
